"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EColors = void 0;
var EColors;
(function (EColors) {
    EColors["red50"] = "#B00211";
    EColors["info20"] = "#002F66";
    EColors["info50"] = "#0076FF";
    EColors["success20"] = "#1B570F";
    EColors["warning20"] = "#663C00";
    EColors["error20"] = "#8A0F0F";
    EColors["neutral20"] = "#2D3439";
    EColors["neutral50"] = "#70838F";
    EColors["white"] = "#FFFFFF";
})(EColors = exports.EColors || (exports.EColors = {}));
